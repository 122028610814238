import { FocusEvent } from 'react'
import { t } from 'i18next'
import qs from 'qs'
import BigNumber from 'bignumber.js'
import { FormInstance } from 'antd'

/**
 * 以刷新方式跳转登录页（清内存，避免干扰）
 */
export const refreshToLoginPage = (searchParams?: Record<string, unknown>) => {
  const search = searchParams ? qs.stringify(searchParams, { addQueryPrefix: true }) : ''
  location.href = `${import.meta.env.VITE_APP_BASE_NAME}/login${search}`
}

/**
 * 邮箱验证（验证通过则返回 true）
 */
export const emailValidate = Object.assign(
  (value: string | null | undefined, ignoreSpaces?: boolean): boolean => {
    value = `${value || ''}`
    if (ignoreSpaces) value = value.replace(/\s+/g, '')
    return !!value && emailValidate.emailReg.test(value)
  },
  {
    /** 邮箱验证正则对象 @link https://emailRegex.com */
    emailReg:
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
)

/**
 * 附件校验（校验不通过则返回提示语）
 */
export const fileValidate = Object.assign(
  (
    file: File | File[],
    {
      accept,
      max,
    }: {
      /** 格式限定，统一使用后缀名 @example `.xls,.xlsx,.xlsm` */
      accept?: string
      /** 大小限定，单位为 MB @default 1 */
      max?: number
    },
  ): string | undefined => {
    if (typeof max !== 'number') max = 1
    max = max || 1

    const fileList = [file].flat(1)
    const acceptList = accept?.split(',').map(v => v.trim().toLowerCase()) || []
    for (const { name, size } of fileList) {
      if (acceptList?.length) {
        if (!acceptList.some(v => name.toLowerCase().endsWith(v))) {
          return t('5-util.ge-shi-jin-xian-ding-wei-accept', { accept })
        }
      }
      if (size > max * 1024 * 1024) {
        return t('5-util.dan-ge-fu-jian-da-xiao-bu-neng-chao-guo-max-mb', { max })
      }
    }
  },
  {
    /** 图片格式 */
    IMG_ACCEPT: `.jpg,.jpeg,.png,.bmp,.gif,.tif,.tiff,.webp` as const,
    /** excel 格式 */
    EXCEL_ACCEPT: `.xls,.xlsx,.xlsm,.xlt,.xltx,.xltm` as const,
    /** 图片格式判断 */
    isImgUrl: (url: string | null | undefined): boolean => {
      const lowerCasePath = `${url || ''}`.split(/\?|#/)[0].trim().toLowerCase()
      const acceptList = fileValidate.IMG_ACCEPT.split(',').map(v => v.trim().toLowerCase())
      return acceptList.some(d => lowerCasePath.endsWith(d))
    },
  },
)

/**
 * Form 表单失焦自动 trim
 */
export const formAutoTrimOnBlur = (opts: {
  /** onBlur 事件对象 */
  event: FocusEvent<HTMLFormElement, Element>
  /** 表单实例 */
  form: FormInstance
  /** 包含字段，默认为全部可输入字段 --- 嵌套字段请用_分隔 */
  includeField?: string[]
  /** 排除字段 --- 嵌套字段请用_分隔 */
  excludeField?: string[]
  /** 触发回调 */
  onTrim?: () => void
}) => {
  const { event, form, includeField, excludeField, onTrim } = opts
  const el = event.target

  if (el instanceof HTMLElement === false) return
  if (el.type !== 'text' && el.type !== 'textarea') return

  const id = el.id
  const name = id.split('_')

  if (!id) return
  if (includeField && !includeField.includes(id)) return
  if (excludeField && excludeField.includes(id)) return

  const v1 = form.getFieldValue(name)
  if (typeof v1 !== 'string') return
  const v2 = v1.trim()
  if (v1 === v2) return
  form.setFieldValue(name, v2)
  form.validateFields([name])
  onTrim?.()
}

/** 统一 Select filterOption  */
export const selectFilterOption = (
  inputValue: string,
  option?: { value?: string | number | null; label?: React.ReactNode; title?: string },
): boolean => {
  inputValue = inputValue.trim().toLocaleLowerCase()

  let optLabel = ''
  // 优先 label
  if (typeof option?.label === 'string' || typeof option?.label === 'number') {
    if (!Number.isNaN(option.label)) {
      optLabel = `${option.label}`.toLocaleLowerCase()
    }
  }
  // 其次 title
  else if (typeof option?.title === 'string' || typeof option?.title === 'number') {
    if (!Number.isNaN(option.title)) {
      optLabel = `${option.title}`.toLocaleLowerCase()
    }
  }

  const optVal = `${option?.value ?? ''}`.toLocaleLowerCase()

  return optLabel.includes(inputValue) || optVal.includes(inputValue)
}

/**
 * 千分位格式化
 * @param opts.placeholder 默认 '--'
 * @param opts.precision 精度，默认 2
 */
export const toThousands = (
  val: BigNumber.Value | null | undefined,
  opts?: {
    /** 默认 '--' */
    placeholder?: string
    /** 精度，默认 2，传 null 表示不处理 */
    precision?: number | null
  },
): string => {
  let { placeholder, precision } = opts || {}
  placeholder = placeholder ?? '--'
  precision = precision === null ? undefined : (precision ?? 2)

  if (val === '' || val == null) {
    return placeholder
  }

  const v = new BigNumber(val).toFormat(precision)
  return v === 'NaN' ? placeholder : v
}
