import { t } from 'i18next'

import { TRoute } from '../interface'
import { auth } from './utils'

/** 财务侧路由表，集中统一配置，路由地址使用首字母小写驼峰命名法 */
export const bizFinance: TRoute<true, '/biz/'>[] = [
  {
    path: '/biz/finance/transaction/recharge',
    handle: { name: () => t('9-nav.chong-zhi-shen-he') },
    lazy: async () => {
      const { Recharge } = await import('@biz/Transaction')
      return { element: auth(<Recharge />, ['103']) }
    },
  },
  {
    path: '/biz/finance/transaction/bankStatement',
    handle: { name: () => t('9-nav.yin-hang-liu-shui') },
    lazy: async () => {
      const { BankStatement } = await import('@biz/Transaction')
      return { element: auth(<BankStatement />, ['103']) }
    },
  },
]
