import { ReactNode } from 'react'

import { Auth } from '../Auth'
import { SetTitle } from '../SetTitle'

/** 设置标题，只需作用于根路由即可 */
export const setTitle = (element: ReactNode) => <SetTitle>{element}</SetTitle>

/** 权限控制 */
export const auth = (element: ReactNode, codes: [string, ...string[]]) => <Auth codes={codes}>{element}</Auth>
