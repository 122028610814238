import { t } from 'i18next'

import { TRoute } from '../interface'
import { auth } from './utils'

/** 仓库侧路由表，集中统一配置，路由地址使用首字母小写驼峰命名法 */
export const bizStore: TRoute<true, '/biz/'>[] = [
  {
    path: '/biz/store/shop/localProductList',
    handle: { name: () => t('9-nav.ben-di-chan-pin') },
    lazy: async () => {
      const { LocalProductList } = await import('@biz/LocalProductList')
      return { element: auth(<LocalProductList isEmployee isEmployeeStore />, ['104']) }
    },
  },
  {
    path: '/biz/store/pkgMaterial',
    handle: { name: () => t('9-nav.bao-cai-guan-li') },
    lazy: async () => {
      const { WrapProductList } = await import('@biz/LocalProductList')
      return { element: auth(<WrapProductList isEmployee isEmployeeStore />, ['104']) }
    },
  },
]
