import { t } from 'i18next'

import { TRoute } from '../interface'
import { auth } from './utils'

/** 商家侧路由表，集中统一配置，路由地址使用首字母小写驼峰命名法 */
export const bizMerchant: TRoute<true, '/biz/'>[] = [
  {
    path: '/biz/nonVIP',
    handle: { name: () => t('0-app.name'), hideLeftSider: true, hideBreadcrumb: true },
    lazy: async () => {
      const { NonVIP } = await import('@biz/NonVIP')
      return { element: auth(<NonVIP />, ['102']) }
    },
  },

  {
    path: '/biz/order/list',
    handle: { name: () => t('9-nav.ding-dan-lie-biao') },
    lazy: async () => {
      const { OrderList } = await import('@biz/Order')
      return { element: auth(<OrderList />, ['102']) }
    },
  },
  {
    path: '/biz/order/error',
    handle: { name: () => t('9-nav.yi-chang-ding-dan') },
    lazy: async () => {
      const { OrderError } = await import('@biz/Order')
      return { element: auth(<OrderError />, ['102']) }
    },
  },
  {
    path: '/biz/order/inFulfillmen',
    handle: { name: () => t('9-nav.yun-dan') },
    lazy: async () => {
      const { OrderInFulfillmen } = await import('@biz/Order')
      return { element: auth(<OrderInFulfillmen />, ['102']) }
    },
  },
  {
    path: '/biz/order/track',
    handle: { name: () => t('9-nav.yun-dan-zhui-zong') },
    lazy: async () => {
      const { OrderTrack } = await import('@biz/Order')
      return { element: auth(<OrderTrack />, ['102']) }
    },
  },

  {
    path: '/biz/shop/list',
    handle: { name: () => t('9-nav.dian-pu-lie-biao') },
    lazy: async () => {
      const { ShopList } = await import('@biz/ShopList')
      return { element: auth(<ShopList />, ['102']) }
    },
  },
  {
    path: '/biz/shop/authorizationResult',
    handle: {
      name: () => t('9-nav.dian-pu-shou-quan-jie-guo'),
      hideLeftSider: true,
      hideBreadcrumb: true,
    },
    lazy: async () => {
      const { ShopAuthorizationResult } = await import('@biz/ShopList')
      return { element: auth(<ShopAuthorizationResult />, ['102']) }
    },
  },
  {
    path: '/biz/shop/productMatch',
    handle: { name: () => t('9-nav.shang-pin-pi-pei') },
    lazy: async () => {
      const { ProductMatch } = await import('@biz/ProductMatch')
      return { element: auth(<ProductMatch />, ['102']) }
    },
  },
  {
    path: '/biz/shop/localProductList',
    handle: { name: () => t('9-nav.ben-di-chan-pin') },
    lazy: async () => {
      const { LocalProductList } = await import('@biz/LocalProductList')
      return { element: auth(<LocalProductList />, ['102']) }
    },
  },

  {
    path: '/biz/logistics/list',
    handle: { name: () => t('9-nav.wu-liu-guan-li') },
    lazy: async () => {
      const { List } = await import('@biz/Logistics')
      return { element: auth(<List />, ['102']) }
    },
  },

  {
    path: '/biz/warehouse/stock',
    handle: { name: () => t('9-nav.ku-cun-lie-biao') },
    lazy: async () => {
      const { Stock } = await import('@biz/Warehouse')
      return { element: auth(<Stock />, ['102']) }
    },
  },
  {
    path: '/biz/warehouse/outOfStock',
    handle: { name: () => t('9-nav.que-huo-ku-cun-lie-biao') },
    lazy: async () => {
      const { OutOfStock } = await import('@biz/Warehouse')
      return { element: auth(<OutOfStock />, ['102']) }
    },
  },
  {
    path: '/biz/warehouse/stockFlow',
    handle: { name: () => t('9-nav.ku-cun-liu-shui') },
    lazy: async () => {
      const { StockFlow } = await import('@biz/Warehouse')
      return { element: auth(<StockFlow />, ['102']) }
    },
  },

  {
    path: '/biz/delivery',
    handle: { name: () => t('9-nav.ti-huo-guan-li') },
    lazy: async () => {
      const { Delivery } = await import('@biz/Delivery')
      return { element: auth(<Delivery />, ['102']) }
    },
  },

  {
    path: '/biz/supplier',
    handle: { name: () => t('9-nav.gong-ying-shang-guan-li') },
    lazy: async () => {
      const { Supplier } = await import('@biz/Supplier')
      return { element: auth(<Supplier />, ['102']) }
    },
  },

  {
    path: '/biz/pkgMaterial',
    handle: { name: () => t('9-nav.bao-cai-guan-li') },
    lazy: async () => {
      const { WrapProductList } = await import('@biz/LocalProductList')
      return { element: auth(<WrapProductList />, ['102']) }
    },
  },

  {
    path: '/biz/transaction/recharge',
    handle: { name: () => t('9-nav.zhang-hu-chong-zhi') },
    lazy: async () => {
      const { Recharge } = await import('@biz/Transaction')
      return { element: auth(<Recharge />, ['102']) }
    },
  },
  {
    path: '/biz/transaction/accountFlow',
    handle: { name: () => t('9-nav.zi-jin-liu-shui') },
    lazy: async () => {
      const { AccountFlow } = await import('@biz/Transaction')
      return { element: auth(<AccountFlow />, ['102']) }
    },
  },
  {
    path: '/biz/transaction/bill',
    handle: { name: () => t('9-nav.wo-de-zhang-dan') },
    lazy: async () => {
      const { Bill } = await import('@biz/Transaction')
      return { element: auth(<Bill />, ['102']) }
    },
  },

  {
    path: '/biz/setting/billing',
    handle: { name: () => 'Billing' },
    lazy: async () => {
      const { Billing } = await import('@biz/Setting')
      return { element: auth(<Billing />, ['102']) }
    },
  },
]
