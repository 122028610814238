/* eslint-disable simple-import-sort/imports */
import ReactDOM from 'react-dom/client'

import 'antd/es/style/reset.css'
import './global.less'

import './window.consts'

import './i18n'
import './axiosConfig'
import { AntdConfig } from './antdConfig'
import { RouterConfig } from './routerConfig'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <AntdConfig>
    <RouterConfig />
  </AntdConfig>,
)

if (import.meta.env.DEV) {
  const oldErrorFn = console.error
  console.error = (...args) => {
    const arg0 = args[0]
    if (typeof arg0 === 'string') {
      if (
        // 消除 react 警告（后续大版本中可能不支持的特性）
        arg0.includes('Support for defaultProps will be removed') || // 来自 antd
        arg0.includes('findDOMNode is deprecated and will be removed in the next major release.') // 来自 antd
      ) {
        return
      }
    }
    return oldErrorFn(...args)
  }
  const oldWarnFn = console.warn
  console.warn = (...args) => {
    const arg0 = args[0]
    if (typeof arg0 === 'string') {
      if (
        // 消除 react-router 警告（后续大版本中可能不支持的特性）
        arg0.includes('`RouterProvider` hydration behavior is changing in v7')
      ) {
        return
      }
    }
    return oldWarnFn(...args)
  }
}
