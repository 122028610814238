/* eslint-disable simple-import-sort/imports */
// 依据 node_modules/.vite/deps/*.js，@chunks稳定性
import {
  BridgeProvider,
  createContext,
  useBridgeValue,
  useContext,
  useContextSelector,
  useContextUpdate,
} from 'use-context-selector'
import ahooks from './ahooks'
import immer from './immer'
import { create, createStore, useStore } from 'zustand'
import axios from './axios'
import lodash from 'lodash'
import dayjs from 'dayjs'
import dayjsZhCN from 'dayjs/locale/zh-cn'
import qs from 'qs'
import classnames from 'classnames'
import bignumberJs, { BigNumber } from 'bignumber.js'
import md5 from 'md5'
import copyToClipboard from 'copy-to-clipboard'

export default {
  BridgeProvider,
  createContext,
  useBridgeValue,
  useContext,
  useContextSelector,
  useContextUpdate,

  ahooks,

  immer,

  create,
  createStore,
  useStore,

  axios,

  lodash,

  dayjs,
  dayjsZhCN,

  qs,

  classnames,

  bignumberJs,
  BigNumber,

  md5,

  copyToClipboard,
}
