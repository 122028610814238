import { Navigate } from 'react-router-dom'
import { t } from 'i18next'

import { PageEmpty } from '@/components/PageEmpty'
import { RouteErrorBoundary } from '@/components/RouteErrorBoundary'
import { fetchUserInfo } from '@/globalStore/userInfo'
import { TRoute } from '../interface'
import { bizAdmin } from './biz.admin'
import { bizFinance } from './biz.finance'
import { bizMerchant } from './biz.merchant'
import { bizStore } from './biz.store'
import { auth, setTitle } from './utils'

/** 404 路由 */
const notFoundRoute: TRoute<true> = {
  path: '*',
  handle: { name: () => t('1-error.notFound') },
  element: setTitle(<PageEmpty type="notFound" />),
}

/** 路由表，集中统一配置，路由地址使用首字母小写驼峰命名法 */
let routes: TRoute[] = [
  {
    path: '/',
    handle: undefined,
    element: <Navigate to={import.meta.env.VITE_APP_HOME_ROUTE_PATH} replace />,
  },
  {
    path: '/login/:pageType?', // register:注册 | passwordReset:密码重置
    handle: { name: () => t('9-nav.deng-lu-zhu-ce-mi-ma-zhong-zhi') },
    lazy: async () => {
      const { Login } = await import('@/pages/Login')
      return { element: setTitle(<Login />) }
    },
  },
  {
    path: '/biz/',
    handle: undefined,
    loader: () => fetchUserInfo().then(() => null),
    lazy: async () => {
      const { Biz } = await import('@biz/Biz')
      return { element: setTitle(<Biz />) }
    },
    children: [
      // 公共
      {
        path: '/biz/transaction/bill/detail/:invoiceId/:merchantUserId',
        handle: { noLayout: true, name: () => t('9-nav.zhang-dan-xiang-qing') },
        lazy: async () => {
          const { Detail } = await import('@biz/Transaction')
          return { element: auth(<Detail />, ['101', '102']) }
        },
      },
      // 商家侧
      ...bizMerchant,
      // 员工侧
      ...bizAdmin,
      // 财务侧
      ...bizFinance,
      // 仓库侧
      ...bizStore,
      { ...notFoundRoute },
    ],
  },
  { ...notFoundRoute },
]

// 路由表公共逻辑处理
routes = routes.map(item => {
  const loop = function <T>({ ...route }: TRoute<T>): TRoute<T> {
    if (!route.errorElement) route.errorElement = <RouteErrorBoundary /> // 异常反馈
    if (route.children) {
      route.children = route.children.map(loop)
    }
    return route
  }
  return loop(item)
})

export { routes }
