import axios from 'axios'

import type { IUserInfo } from '.'
import './__mock__'

export const apiGetUserInfo = async (): Promise<IUserInfo> => {
  return axios.get(`/linkhub_oms/user/info`).then(({ $data }) => {
    $data = { ...$data }

    $data.userName = $data.userName || ''
    $data.roleCodeMap = ($data.roleCodes as string[]).reduce((obj, item) => ({ ...obj, [item]: item }), {})

    return $data
  })
}
