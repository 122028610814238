import { ThemeConfig } from 'antd'

/**
 * 主题编辑器
 * @link https://ant-design.antgroup.com/theme-editor-cn
 *
 * 主题变量
 * @link https://ant-design.antgroup.com/docs/react/customize-theme-cn#seedtoken
 */
export const themeVars: Pick<ThemeConfig, 'token' | 'components'> = {
  token: {
    fontFamily: 'Inter, Helvetica, sans-serif', // 同 src/global.less
    fontSize: 13, // 同 src/global.less
    lineHeight: 1.5, // 同 src/global.less

    colorPrimary: '#6f2c91',
    colorSuccess: '#50cd89',
    colorInfo: '#6f2c91',
    colorWarning: '#ffc700',
    colorError: '#f1416c',
    colorTextBase: '#1e2129',
  },
  components: {
    Form: {
      itemMarginBottom: 16,
      verticalLabelPadding: 3,
    },
    Table: {
      headerBg: '#fff',
      headerBorderRadius: 0,
    },
    Badge: {
      statusSize: 8,
    },
  },
}
