import { useRouteError } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Button, Result } from 'antd'

import { REFRESH_TO_LOGIN_PAGE_MSG } from '@/consts/consts'

/** 路由组件异常反馈 */
export const RouteErrorBoundary = () => {
  const { t } = useTranslation()
  const err = useRouteError()
  let msg

  if (err instanceof Error) {
    msg = err.message
  }
  msg = `${(err instanceof Error && err.name) || 'unknown'}::${msg}`

  if (msg.includes(REFRESH_TO_LOGIN_PAGE_MSG)) return null

  if (import.meta.env.DEV || import.meta.env.VITE_APP_ENV === 'staging') {
    msg = (
      <>
        <div>以下信息只会在开发环境或staging环境中显示</div>
        {msg}
      </>
    )
  } else {
    msg = null
  }

  return (
    <Result
      status="error"
      title={`${t('1-error.errTitle')}`}
      subTitle={msg}
      extra={<Button onClick={() => location.reload()}>{t('1-error.clickRefresh')}</Button>}
    />
  )
}
