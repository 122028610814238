/* eslint-disable simple-import-sort/imports */
import { ReactNode, useMemo } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { ConfigProvider, ConfigProviderProps } from 'antd'
import zhCN from 'antd/locale/zh_CN'

import './setDefaultProps'
import { themeVars } from './themeVars'
import { componentConfig } from './componentConfig'
import { getGlobalState, subscribeGlobalState, useGlobalState } from '@/globalStore/globalStore'
import './overwrite.module.less'

dayjs.locale(getGlobalState().lang === 'zh' ? 'zh-cn' : 'en')
subscribeGlobalState((v1, v2) => {
  if (v1.lang !== v2.lang) dayjs.locale(v1.lang === 'zh' ? 'zh-cn' : 'en')
})

const config: ConfigProviderProps = {
  ...componentConfig,
  theme: {
    ...themeVars,
    cssVar: { key: 'ShipO' },
    hashed: false,
  },
  prefixCls: undefined, // 使用默认
  iconPrefixCls: undefined, // 使用默认
}

document.documentElement.classList.add('ShipO') // 把全局变量暴露到 html 节点

ConfigProvider.config({
  holderRender: children => <ConfigProvider {...config}>{children}</ConfigProvider>, // 只能配置一次，否则 message 无效
})

export const AntdConfig = function ({ children }: { children: ReactNode }) {
  const lang = useGlobalState(s => s.lang)

  const configProviderProps = useMemo<ConfigProviderProps>(
    () => ({
      ...config,
      locale: lang === 'zh' ? zhCN : undefined,
    }),
    [lang],
  )

  return <ConfigProvider {...configProviderProps}>{children}</ConfigProvider>
}
