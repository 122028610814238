import { t } from 'i18next'

import { TRoute } from '../interface'
import { auth } from './utils'

/** 员工侧路由表，集中统一配置，路由地址使用首字母小写驼峰命名法 */
export const bizAdmin: TRoute<true, '/biz/'>[] = [
  {
    path: '/biz/admin/order/audit',
    handle: { name: () => t('9-nav.lv-yue-dan-shen-he') },
    lazy: async () => {
      const { AdminOrderAudit } = await import('@biz/Order')
      return { element: auth(<AdminOrderAudit />, ['101']) }
    },
  },
  {
    path: '/biz/admin/order/error',
    handle: { name: () => t('9-nav.yi-chang-lv-yue-dan') },
    lazy: async () => {
      const { AdminOrderError } = await import('@biz/Order')
      return { element: auth(<AdminOrderError />, ['101']) }
    },
  },
  {
    path: '/biz/admin/order/track',
    handle: { name: () => t('9-nav.yun-dan-zhui-zong') },
    lazy: async () => {
      const { OrderTrack } = await import('@biz/Order')
      return { element: auth(<OrderTrack />, ['101']) }
    },
  },

  {
    path: '/biz/admin/shop/localProductList',
    handle: { name: () => t('9-nav.ben-di-chan-pin') },
    lazy: async () => {
      const { LocalProductList } = await import('@biz/LocalProductList')
      return { element: auth(<LocalProductList isEmployee />, ['101']) }
    },
  },

  {
    path: '/biz/admin/logistics/list',
    handle: { name: () => t('9-nav.wu-liu-lie-biao') },
    lazy: async () => {
      const { List } = await import('@biz/Logistics')
      return { element: auth(<List isEmployee />, ['101']) }
    },
  },
  {
    path: '/biz/admin/logistics/freightTemplates',
    handle: { name: () => t('9-nav.yun-fei-mo-ban') },
    lazy: async () => {
      const { FreightTemplate } = await import('@biz/Logistics')
      return { element: auth(<FreightTemplate />, ['101']) }
    },
  },

  {
    path: '/biz/admin/warehouse/stock',
    handle: { name: () => t('9-nav.ku-cun-lie-biao') },
    lazy: async () => {
      const { Stock } = await import('@biz/Warehouse')
      return { element: auth(<Stock isEmployee />, ['101']) }
    },
  },
  {
    path: '/biz/admin/warehouse/outOfStock',
    handle: { name: () => t('9-nav.que-huo-ku-cun-lie-biao') },
    lazy: async () => {
      const { OutOfStock } = await import('@biz/Warehouse')
      return { element: auth(<OutOfStock isEmployee />, ['101']) }
    },
  },
  {
    path: '/biz/admin/warehouse/stockFlow',
    handle: { name: () => t('9-nav.ku-cun-liu-shui') },
    lazy: async () => {
      const { StockFlow } = await import('@biz/Warehouse')
      return { element: auth(<StockFlow isEmployee />, ['101']) }
    },
  },

  {
    path: '/biz/admin/delivery',
    handle: { name: () => t('9-nav.ti-huo-guan-li') },
    lazy: async () => {
      const { Delivery } = await import('@biz/Delivery')
      return { element: auth(<Delivery isEmployee />, ['101']) }
    },
  },

  {
    path: '/biz/admin/pkgMaterial',
    handle: { name: () => t('9-nav.bao-cai-guan-li') },
    lazy: async () => {
      const { WrapProductList } = await import('@biz/LocalProductList')
      return { element: auth(<WrapProductList isEmployee />, ['101']) }
    },
  },

  {
    path: '/biz/admin/transaction/recharge',
    handle: { name: () => t('9-nav.zhang-hu-chong-zhi') },
    lazy: async () => {
      const { Recharge } = await import('@biz/Transaction')
      return { element: auth(<Recharge />, ['101']) }
    },
  },
  {
    path: '/biz/admin/transaction/accountFlow',
    handle: { name: () => t('9-nav.zi-jin-liu-shui') },
    lazy: async () => {
      const { AccountFlow } = await import('@biz/Transaction')
      return { element: auth(<AccountFlow />, ['101']) }
    },
  },
  {
    path: '/biz/admin/transaction/bill',
    handle: { name: () => t('9-nav.zhang-dan-lie-biao') },
    lazy: async () => {
      const { Bill } = await import('@biz/Transaction')
      return { element: auth(<Bill />, ['101']) }
    },
  },
  {
    path: '/biz/admin/transaction/bankStatement',
    handle: { name: () => t('9-nav.yin-hang-liu-shui') },
    lazy: async () => {
      const { BankStatement } = await import('@biz/Transaction')
      return { element: auth(<BankStatement />, ['101']) }
    },
  },

  {
    path: '/biz/admin/whiteList',
    handle: { name: () => t('9-nav.bai-ming-dan-guan-li') },
    lazy: async () => {
      const { WhiteList } = await import('@biz/WhiteList')
      return { element: auth(<WhiteList />, ['100', '101']) }
    },
  },
]
